<template>
  <div class="columns">
    <b-modal v-model="playing" width="90%" scroll="keep">

      <recording-player :source="this.src">
      </recording-player>

    </b-modal>

    <div class="column is-7 ">
      <div class="tile is-ancestor is-vertical">
        <div class="tile is-parent">

          <b-message class="tile is-child" v-if="data.status === 'irrelevant'" type="is-success" size="is-medium"
                     icon="alarm-light-off-outline"
                     has-icon>
            Incident has finished and has been handled. Great job.
          </b-message>

          <b-message class="tile is-child" v-else-if="data.status === 'relevant'" type="is-success" size="is-medium"
                     icon="alarm-light-off-outline"
                     has-icon>
            Incident has finished and has been handled. Great job.
          </b-message>

          <b-message class="tile is-child" v-else-if="data.ongoing" type="is-danger" size="is-medium"
                     icon="alarm-light" has-icon>
            Incident is ongoing! Please respond now.
          </b-message>

          <b-message class="tile is-child" v-else type="is-grey" size="is-medium"
                     icon="alarm-light" has-icon>
            Incident has not been updated on the portal.
          </b-message>

        </div>


        <div class="tile is-parent">

          <card-component class="tile is-child has-table" style="min-height: 40vh " title="Incident timeline"
                          icon="history" :is-scrollable="false" :has-button-slot="true"
                          :has-footer-slot="true">

            <b-switch size="is-small" slot="button" v-model="snapshots">snapshots</b-switch>

            <b-loading :is-full-page="false" v-model="loading" :can-cancel="true"></b-loading>

            <div v-if="!loading" class="timeline py-4 pl-2 pr-2">


              <header class="timeline-header pl-2" style="justify-content: left">
              <span class="tag has-text-left is-danger is-light">{{
                  data.created_at | moment("dddd DD MMM YYYY")
                }}</span>
              </header>


              <transition-group name="list-complete" tag="div">

                <div v-if="data.ongoing" key="start" class="timeline-item list-complete-item pb-1 ">
                  <div class="timeline-content pl-5 pt-3 level">
                    <div class="level-left">
                      <b-button class="level-item" style="border: none" size="is-small" loading></b-button>
                      <span class="level-item text is-size-7 has-text-grey">new events will appear automatically</span>
                    </div>
                  </div>
                </div>

                <div v-for="item in ts_items" :key="item.id" class="timeline-item list-complete-item pb-0">

                  <template v-if="item.type === 'log' && item.data.properties.name !== 'ai_triggered'">
                    <div :class="'timeline-marker is-icon ' + item.data.properties.type">
                      <b-icon custom-size="mdi-18px" :icon="item.data.properties.icon"></b-icon>
                    </div>
                    <div class="timeline-content mb-3 pt-0">
                      <div class="level pt-2 mb-0 is-mobile">
                        <div class="level-left">
                          <span class="heading level-item mb-0">{{ item.ts | moment("HH:mm:ss") }}</span>
                          <template v-if="item.data.user">
                            <b-icon class="level-item mr-1" size="is-small" icon="account"></b-icon>
                            <span class=" level-item mb-0 ">{{ item.data.user.name }}</span>
                          </template>

                        </div>
                      </div>
                      <p>{{ item.data.description }}</p>
                    </div>
                  </template>

                  <template v-if="item.type === 'event'">
                    <div class="timeline-marker is-icon is-danger">
                      <b-icon custom-size="mdi-18px" icon="motion-sensor"></b-icon>
                    </div>
                    <div class="timeline-content mb-3 pt-0" style="width: 100%">
                      <p class="heading mb-0">{{ item.ts | moment("HH:mm:ss") }}</p>
                      <p>{{ item.data.trigger }} detected on channel {{ item.data.channel }}</p>

                      <div v-if="item.data.snapshot && snapshots" class="box px-0 ml-0 mx-0 mt-2 pb-0"
                           style="max-width: 500px; overflow: hidden"
                           v-bind:class="{'has-table no-outside': true}">
                        <div style="margin: -20px; cursor: pointer" class="card-image image image-container">

                          <b-image class="theimage gradient ml-4 mb-4"
                                   :src="item.data.snapshot"
                                   alt="Image Loading"
                                   @click.native="imageModal(item.data.snapshot)"
                                   :ratio="'16by9'"
                                   :rounded="false"
                          >

                            <b-skeleton
                              class="skeleton-placeholder"
                              slot="placeholder"
                              height="100%"
                            ></b-skeleton>

                          </b-image>
                        </div>
                      </div>

                      <b-collapse
                        v-if="item.data.recordings.length > 0"
                        :open="false"
                        position="is-top"
                        aria-id="contentIdForA11y4"
                        class="pt-2"
                      >
                        <template #trigger="props">
                          <b-taglist class="mb-2" attached>

                            <b-tag
                              type=""
                              aria-controls="contentIdForA11y4"
                              :aria-expanded="props.open"
                              :icon="!props.open ? 'menu-down' : 'menu-up'"
                              icon-size="is-small"
                            >
                              {{ !props.open ? 'View recordings' : 'Hide recordings' }}
                            </b-tag>
                            <b-tag v-if="item.data.recordings.every(r => r.downloaded_at !== null)" icon="check"
                                   type="is-success is-light">All downloaded
                            </b-tag>
                            <b-tag v-else icon="autorenew">Not downloaded yet</b-tag>

                          </b-taglist>
                        </template>
                        <div class="media-list">
                          <recording-item v-for="recording in item.data.recordings" :key="recording.id"
                                          :recording="recording"
                                          v-on:update="fetchData" v-on:play="setSource"/>
                        </div>
                      </b-collapse>

                    </div>
                  </template>

                </div>

              </transition-group>

              <!--        <header class="timeline-header">-->
              <!--          <span style="border: none" class="button i-small is-loading"></span>-->
              <!--        </header>-->


              <header class="timeline-header pt-3 pl-2" style="justify-content: left">
              <span class="tag has-text-left is-danger is-light">{{
                  data.created_at | moment("dddd DD MMM YYYY")
                }}</span>
              </header>

              <br>

            </div>


          </card-component>


        </div>
      </div>
    </div>

    <div class="column">

      <card-component class="">
        <div class="media">
          <div class="media-left">
            <eye-icon :status="project.status" type="project" :has-text="false"/>
          </div>
          <div class="media-content">
            <p class="title is-4">{{ project.name }}</p>
            <p class="subtitle is-6">{{ project.address }} {{ project.nr }} {{ project.town }}</p>
            <nav class="level is-mobile">

              <div class="level-left">
                <b-tag class="level-item" v-if="project.company">{{ project.company.name }}</b-tag>
              </div>

              <div class="level-right">
                <a class="level-item" @click="$router.push({ name:'project.edit', params: {id: project.id}})">
                  <b-icon size="is-small" icon="pencil"/>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </card-component>

      <div class="pb-5" v-can="'verify all alerts'">

        <!--          <card-component class="tile is-child" :has-title-slot="false">-->
        <div class="buttons">
          <b-button type="is-success is-light"
                    expanded
                    icon-left="check"
                    @click.prevent="promptIncidentUpdate('irrelevant')">
            mark incident as false
          </b-button>
          <b-button type="is-success is-danger is-light"
                    expanded
                    icon-left="alert"
                    @click.prevent="promptIncidentUpdate('relevant')">
            mark incident as alarm
          </b-button>
        </div>
        <!--          </card-component>-->

      </div>

      <card-component title="Related Snapshots" icon="image" class=" has-table">
        <b-carousel v-if="true"
                    :autoplay="false"
                    :arrow-hover="false"
                    :arrow="true"
                    icon-size="is-medium"
                    with-carousel-list
                    :indicator="true"
                    :indicator-background="false"
                    indicator-style="is-lines"
                    :overlay="gallery"
                    @click="switchGallery(true)">
          <b-carousel-item v-for="(item, i) in images" :key="i">
            <b-image ratio="16by9" :src="item.image">
              <template #placeholder>
                <b-skeleton
                  class="skeleton-placeholder"
                  height="100%"
                ></b-skeleton>
              </template>
            </b-image>
          </b-carousel-item>
          <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large"/>

        </b-carousel>
        <template v-if="images.length === 0">
          <br>
          <p class="has-text-centered">
            <b-icon icon="cactus" size="is-large"/>
          </p>
          <p class="has-text-centered">No snapshots found for this incident</p>
          <br>
        </template>

      </card-component>


      <card-component title="Related Recordings" icon="file-video" class="tile is-child">
        <div class="media-list">
          <b-loading :is-full-page="false" :active="false"/>
          <recording-item v-for="recording in recordings" :key="recording.id" :recording="recording"
                          v-on:update="fetchData" v-on:play="setSource"/>
        </div>

        <template v-if="recordings.length === 0">
          <br>
          <p class="has-text-centered">
            <b-icon icon="cactus" size="is-large"/>
          </p>
          <p class="has-text-centered">No recordings found for this incident</p>
          <br>
        </template>
      </card-component>


    </div>
  </div>

</template>

<script>
import packages from "@/store/modules/packages.store"
import projects from "@/store/modules/projects.store"
import CardComponent from "@/components/CardComponent";
import RefreshButton from '@/components/RefreshButton'
import AlertDetailComponent from "@/components/package/AlertDetailComponent";
import EyeIcon from "@/components/EyeIcon";
import moment from 'moment'
import RecordingItem from "@/components/package/RecordingItem";
import RecordingPlayer from "@/components/package/RecordingPlayer.vue";


export default {
  name: "IncidentDetailComponent",
  components: {RecordingPlayer, RecordingItem, EyeIcon, AlertDetailComponent, CardComponent, RefreshButton},
  props: ['incident_id', 'project_id', 'package_name', 'project_name'],

  data() {
    return {
      data: [],
      loading: true,
      timeline_items: [],
      project: {},
      snapshots: true,
      gallery: false,
      images: [],
      recordings: [],

      src: null,
      playing: false,
      playerOptions: {
        controls: ['play-large', 'progress', 'play', 'airplay', 'pip', 'current-time', 'mute', 'volume', 'settings', 'fullscreen'],
        settings: ['quality', 'speed', 'loop'],
        fullscreen: {enabled: true, fallback: true, iosNative: true, container: null},
        autoplay: true,
      }
    }
  },
  watch: {
    incident_id: function () {
      this.fetchData()
    }
  },

  created() {
    if (!packages.isRegistered) {
      this.$store.registerModule('packages', packages)
      packages.isRegistered = true
    }
    if (!projects.isRegistered) {
      this.$store.registerModule('projects', projects)
      projects.isRegistered = true
    }
  },

  mounted() {
    this.fetchData()
    this.fetchProjectData()
    this.intervalId = window.setInterval(() => {
      this.fetchData(false);
    }, 2500);
  },

  beforeDestroy: function () {
    clearInterval(this.intervalId);
  },

  computed: {
    ts_items() {
      return this.timeline_items.sort((a, b) => -moment(a.ts).diff(moment(b.ts)))
    },
  },

  methods: {
    fetchData(show = true) {
      if (show) {
        this.loading = true
      }
      //this.timeline_items = []

      if (this.project_id) {
        this.$store.dispatch('projects/fetchIncidentDetails', {
          project_id: this.project_id,
          incident_id: this.incident_id
        })
          .then(response => {
            this.data = response.data.data
            let curlist = []

            this.data.logs.forEach(e => {
              curlist.push({
                id: 'i' + e.id,
                ts: e.created_at,
                type: 'log',
                data: e
              })
            })

            this.data.projectlogs.forEach(e => {
              curlist.push({
                id: 'ip' + e.id,
                ts: e.created_at,
                type: 'log',
                data: e
              })
            })

            let curimgs = []
            let currecs = []
            this.data.events.forEach(e => {
              curlist.push({
                id: 'e' + e.id,
                ts: e.timestamp,
                type: 'event',
                data: e
              })
              curimgs.push({ts: e.timestamp, image: e.snapshot})
              // put recordings on list
              e.recordings.forEach(r => {
                currecs.push(r)
              })

            })
            this.images = curimgs
            this.recordings = currecs
            this.timeline_items = curlist
            this.loading = false


          })
      }

    },
    fetchMore(page) {
      this.page = page
      this.fetchData()
    },

    fetchProjectData() {
      let formData = {
        id: this.project_id,
        params: ""
      }
      this.$store.dispatch('projects/fetchProjectDetails', formData)
        .then(response => {
          this.project = response.data.data
          if (this.project.packages.length > 0) {
          } else {
            this.hasPackages = false;
          }


        })
    },


    /*
    * Handle sort event
    */
    onSort(field, order) {
      this.sort_field = field
      this.sort_order = order
      this.fetchData()
    },

    imageModal(img) {
      const h = this.$createElement
      const vnode = h('p', {class: "image"}, [
        h('img', {attrs: {src: img}})
      ])
      this.$buefy.modal.open({
        content: [vnode],
        width: "90%"
      })
    },

    videoModal(vid) {
      const h = this.$createElement
      const vnode = h('p', {class: "image"}, [
        h('img', {attrs: {src: img}})
      ])
      this.$buefy.modal.open({
        content: [vnode]
      })
    },

    setSource(src) {
      console.log(src)
      this.src = src
      this.playing = true;

    },

    switchGallery(value) {
      this.gallery = value
      if (value) {
        return document.documentElement.classList.add('is-clipped')
      } else {
        return document.documentElement.classList.remove('is-clipped')
      }
    },

    promptIncidentUpdate(status) {
      this.$buefy.dialog.prompt({
        message: `Description of incident approval`,
        inputAttrs: {
          placeholder: 'e.g. no persons were detected on site',
        },
        trapFocus: true,
        onConfirm: (value) => this.updateIncident(status, value)
      })
    },

    updateIncident(status, message) {
      this.$store.dispatch('projects/updateIncident', {
        project_id: this.project_id,
        incident_id: this.incident_id,

        formData: {status: status, message: message}
      })
        .then(response => {
          this.data.status = status
          this.fetchData()
          this.$emit("refresh")
          this.$buefy.snackbar.open({
            message: `Marked as ${status}`,
            queue: false
          })
        })
    },

  }


}
</script>

<style scoped>

.vjs-poster {
  background-size: 100% !important;
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;

}

.v-enter-from,
.v-leave-to {
  opacity: 0;

}

.heading {
  font-size: 12px;
}

.list-complete-item {
  transition: all 0.5s;
  margin-right: 10px;
}

.list-complete-enter, .list-complete-leave-to
  /* .list-complete-leave-active below version 2.1.8 */
{
  opacity: 0;
  transform: translateY(-30px);
}

.list-complete-leave-active {
  position: absolute;
}

/deep/ .b-skeleton {
  height: 100%;
  position: absolute;
  top: 0;
}


/* Container needed to position the overlay. Adjust the width as needed */
.image {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Make the image to responsive */
.theimage {
  display: block;
  position: relative;
  width: 102%;
  height: 100%;
}

/deep/ .b-skeleton {
  height: 100%;
  position: absolute;
  top: 0;
}

</style>
