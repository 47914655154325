<template>
  <section class="section is-main-section">
    <title-bar :title-stack="titleStack"/>

    <incident-detail-component :incident_id="this.$route.params.i_id"
                               :project_id="this.$route.params.id"
                               @refresh="$emit('refresh')"/>

  </section>

</template>

<script>
import TitleBar from "../components/TitleBar";
import IncidentDetailComponent from "@/components/package/IncidentDetailComponent";

export default {
  name: 'ProjectIncident',
  components: {IncidentDetailComponent, TitleBar},

  computed: {
    titleStack() {
      return [
        this.$route.params.project_name,
        'Incident',
        this.$route.params.i_id
      ]
    },
  }
}
</script>

<style lang="scss">


</style>
