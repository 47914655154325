<template>
  <section class="section is-main-section">
    <title-bar :status="header_status" :title-stack="header_stack"
               :message="'Welcome back, ' + user.name"
               :submessage="'You have ' + updates + ' new notifications.'"></title-bar>


    <div class="columns is-multiline">

      <div class="column is-full-mobile is-full-tablet is-7-desktop is-7-fullhd">
        <transition v-on:leave="resize" name="fade" mode="out-in">
          <router-view name="main_view"/>
        </transition>

      </div>

      <div class="column">
        <div class="tile is is-ancestor is-vertical">
          <div class="tile is-parent">
            <article class="tile is-child map">
              <card-component class="has-table no-outside" style="overflow: hidden">
                <div v-bind:class="'main-map-div'" :style="{ overflow: 'hidden', width: '100%' }">
                  <mapbox style="overflow: hidden; height: 100%" ref="mymap" :access-token="accessToken"
                          :map-options="mapStyle"
                          :geolocate-control="{
                                show: true,
                                position: 'top-right',
                              }"
                          @map-init="mapInitialized">
                  </mapbox>
                </div>
              </card-component>
            </article>
          </div>

          <div class="tile is-parent">
            <article class="tile is-child">
              <transition v-on:leave="resize" name="fade" mode="out-in">
                <router-view name="side_view"/>
              </transition>

            </article>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child ">


            </article>
          </div>

        </div>

      </div>

    </div>

  </section>
</template>

<script>
// @ is an alias to /src
import Mapbox from 'mapbox-gl-vue'
import * as markers from "../maps/markerStyles";
import mapbox from "@/store/modules/mapbox.store"
import TitleBar from "../components/TitleBar";
import CardComponent from "../components/CardComponent";


export default {
  name: 'home',
  components: {
    CardComponent,
    TitleBar,
    Mapbox,
  },
  data() {
    return {
      the_package: null,

      accessToken: 'pk.eyJ1IjoibHVja2FzZGMiLCJhIjoiY2sxdWR6d3B5MDkwOTNpcGFuNXh3dGhzNSJ9.nBYgv6LPBGFGDx56p8mRQQ',
      mapStyle: {
        style: 'mapbox://styles/luckasdc/ckf29lnav0mm319qvg7u55h7l',
      },
      publicPath: process.env.BASE_URL,
      selected: null,

      mapExpand: 'main-map-div',

    }
  },

  created() {
    if (!mapbox.isRegistered) {
      this.$store.registerModule('mapbox', mapbox)
      mapbox.isRegistered = true
    }
  },


  ready() {
    // We need to set mapbox-gl library here in order to use it in template
    // this.mapbox = Mapbox
    //this.$store.dispatch('mapbox/initMapbox', Mapbox )

  },
  computed: {

    mymap() {
      return this.$store.state.mapbox.mymap
    },
    mapbox() {
      return this.$store.state.mapbox.mapbox
    },
    expanded() {
      return this.$store.state.mapbox.expanded
    },
    header_stack() {
      return this.$store.state.mapbox.header_stack
    },
    header_status() {
      return this.$store.state.mapbox.header_status
    },
    user() {
      return this.$store.state.auth.user
    },
    updates() {
      return this.$store.state.auth.updates.length
    }

  },

  methods: {

    mapInitialized: function (map) {

      this.$store.dispatch('mapbox/initMap', map)

    },
    resize() {
      setTimeout(() => this.$store.dispatch('mapbox/resize'), 700);

    }

  }
}
</script>

<style lang="scss">

@media(max-width: 767px) { /* <== You can change this break point as per your  needs */
  .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }
}

.tile.is-child.map {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.main-map-div {
  height: 45vh;
  transition: all 500ms ease;
}

#map {
  width: 100%;
}

#wrapper {
  position: relative;
}

#over_map {
  position: absolute;
  top: 20px;
  left: 20px;

  z-index: 90;
}


#app .marker {
  z-index: 10;
}


</style>
