<template>
  <div  class="media mb-0 mt-0 pl-2 pr-2 pt-3 pb-3">
<!--    <figure class="media-left">-->
<!--      <b-image style="height: 100%; width: 100px" :src="alert.snapshot" ratio="5by3" @click.native.stop="imageModal"-->
<!--      src-fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8Wg8AAk8BZuQcWLwAAAAASUVORK5CYII=">-->
<!--      </b-image>-->
<!--    </figure>-->
      <div class="media-content">

        <nav class="level is-mobile mb-0">
          <div class="level-left">
            <p class="level-item">
              <strong class="level-item">Incident n° {{ alert.id }}</strong>
            </p>
          </div>
          <div class="level-right">
            <div v-if="alert.status === 'pending'" class="level-item">
              <b-tag type="is-warning is-light">pending</b-tag>
            </div>

            <div  v-if="alert.status === 'irrelevant'" class="level-item">
              <b-tag type="is-success is-light">irrelevant</b-tag>
            </div>

            <div  v-if="alert.status === 'relevant'" class="level-item">
              <b-tag type="is-danger is-light">relevant</b-tag>
            </div>
          </div>
        </nav>

        <div class="content">
          <p>
            <small>{{  alert.created_at | moment("from", "now") }}</small>
            <br>
            <small><b>{{ alert.events_count }}</b> events, <b>{{ alert.recordings_count }}</b> recordings</small>
          </p>
        </div>
      </div>

    </div>
</template>

<script>

  import { StatusIndicator } from 'vue-status-indicator';

  export default {
  name: 'IncidentItem',
  props: ['alert', 'project_id'],
  components: { StatusIndicator, },

  methods: {

    statusLayout(status){
      if(status === "relevant"){
        return {
          type: 'is-success',
          msg: 'Marked as relevant',
          pulse: false,
            color: "negative"
        }
      } else if(status === "irrelevant"){
        return {
          type: 'is-grey',
          msg: 'Marked as irrelevant',
          pulse: false,
            color: "positive"

        }
      } else if(status === "pending"){
        return {
          type: 'is-warning',
          msg: 'To validate',
          pulse: true,
            color: "negative"
        }
      }
    },

    approveAlert (alert) {
        this.$store.dispatch('packages/updateAlert', {project_id: this.project_id, alert_id: alert.id, formData: { status: 'relevant'}} )
            .then(response => {
                alert.status = 'relevant'
                this.$buefy.snackbar.open({
                    message: 'Marked as relevant',
                    queue: false
                })
            })


        alert.status = 'relevant'
      this.$buefy.snackbar.open({
        message: 'Marked as relevant',
        queue: false
      })
    },
    denyAlert (alert) {
        this.$store.dispatch('packages/updateAlert', {project_id: this.project_id, alert_id: alert.id, formData: { status: 'irrelevant'}} )
            .then(response => {
                alert.status = 'irrelevant'
                this.$buefy.snackbar.open({
                    message: 'Marked as irrelevant',
                    queue: false
                })
            })

    },
    imageModal() {
      const h = this.$createElement
      const vnode = h('p', { class: "image" }, [
        h('img', { attrs: { src: this.alert.snapshot }})
      ])
      this.$buefy.modal.open({
        content: [ vnode ]
      })
    },
  }
}
</script>

<style scoped>
  /deep/ .b-skeleton {
    height: 100%;
    position: absolute;
    top: 0;
  }
</style>
