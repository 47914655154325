<template>
  <div class="tile is-ancestor is-vertical">

    <div class="tile is-parent">

      <card-component class="tile is-child">
        <div class="media">
          <div class="media-left">
            <eye-icon :status="project.status" type="project" :has-text="false"/>
          </div>
          <div class="media-content">
            <p class="title is-4">{{ project.name }}</p>
            <p class="subtitle is-6">{{ project.address }} {{ project.nr }} {{ project.town }}</p>
            <nav class="level is-mobile">

              <div class="level-left">
                <b-tag class="level-item" v-if="project.company">{{ project.company.name }}</b-tag>
              </div>

              <div class="level-right">
                <a class="level-item" @click="$router.push({ name:'project.edit', params: {id: project.id}})">
                  <b-icon size="is-small" icon="pencil"/>
                </a>
              </div>
            </nav>
          </div>
        </div>
      </card-component>
    </div>

    <div id="scrollto-alerts">
      <div class="tile is-parent">
        <incident-list-component
          class="tile is-child"
          v-if="loaded && ($gates.hasAnyPermission(`${project.id}.view alerts|view all alerts`) || $gates.isSuperUser())"
          :project="project"/>
      </div>
      <div class="tile is-parent">
        <alert-list-component
          class="tile is-child"
          v-if="loaded && ($gates.hasAnyPermission(`${project.id}.view alerts|view all alerts`) || $gates.isSuperUser())"
          :project="project"/>
      </div>
    </div>

  </div>

</template>

<script>
import projects from "@/store/modules/projects.store"
import AlertListComponent from "../components/package/AlertListComponent";
import IncidentListComponent from "@/components/package/IncidentListComponent";
import EyeIcon from "@/components/EyeIcon";
import CardComponent from "@/components/CardComponent";

const GeoJSON = require('geojson');


export default {
  name: 'ProjectDetailSide',
  components: {
    CardComponent,
    EyeIcon,
    IncidentListComponent,
    AlertListComponent,
  },
  data() {
    return {
      project: {},
      hasPackages: true,
      loaded: false

    }
  },
  computed: {
    titleStack() {
      return [
        'Projects'
      ]
    },
    id() {
      return parseInt(this.$route.params.id)
    },

    //...mapGetters({
    //    currentProject: 'projects/getProjectById'
    //})
  },

  created() {
    if (!projects.isRegistered) {
      this.$store.registerModule('projects', projects)
      projects.isRegistered = true
    }
  },

  beforeMount() {
    this.fetchData()

  },

  methods: {

    fetchData() {
      let formData = {
        id: this.id,
        params: ""
      }
      this.$store.dispatch('projects/fetchProjectDetails', formData)
        .then(response => {
          this.project = response.data.data
          if (this.project.packages.length > 0) {
            this.loaded = true
          } else {
            this.hasPackages = false;
            this.loaded = true;

          }


        })
    },


  }
}
</script>

<style lang="scss">

</style>
