<template>
  <div class="tile is-ancestor is-vertical">

      <div class="tile is-parent pb-0" v-if="hasPackages">
        <div class="tile is-child">

          <div class=" level is-mobile mb-2 pl-1">
            <div class=" level-left">
              <p class="level-item has-text-left mb-0 py-0">
                <b-icon size="is-small" class="mr-2" icon="eye"></b-icon>
                <span style="margin-right:  0.7rem;" class="title is-6">Recent Snapshots</span>
              </p>
            </div>
            <div v-if="loaded" class="level-right">
              <p class="level-item py-0">
                <small class="is-5">{{ project.cameras ? project.cameras.length : "no"}} cameras</small>
              </p>
            </div>
          </div>
        </div>
      </div>


      <div v-if="project" class="tile is-parent pt-0" style="max-width: 95vw">
        <b-carousel-list v-permission:any="`${id}.view camera|view all cameras`" class="tile is-child" :data="project.cameras"  :items-to-show="isMobile() ? 1.1 : 2.4">
          <template slot="item" slot-scope="camera">

            <camera-snapshot-component  :project_id="id" :camera_id="camera.id" :name="camera.name" :thumb="true" ></camera-snapshot-component>

          </template>
        </b-carousel-list>
      </div>



      <div class="tile is-parent" >
        <card-component class="tile is-child" :is-scrollable="false" title="Towers & Sets" icon="eye">
          <div class="media-list" v-if="loaded">
            <div class="media" v-for="a_package in project.packages"  style="cursor:pointer" @click="$router.push({ name:'package.detail', params: {project_id: project.id, id: a_package.id} })">
              <div class="media-left">
                <eye-icon class="pt-2" :status="a_package.status" type="project" :has-text="false"/>
              </div>
              <div class="media-content has-text-left">
                <div class="text has-text-weight-bold is-6">{{ a_package.name }}</div>
                <!--                            <p class="subtitle is-6">{{ project.address }} {{ project.nr }} {{ project.town }}</p>-->
<!--                <status-component :status="a_package.status"/>-->
                <div class="level is-mobile">
                  <div class="level-left">
                    <StatusLineComponent :status="a_package.status"></StatusLineComponent>
                  </div>
                </div>

              </div>
            </div>
            <div v-if="project.packages.length === 0" class="media">
              <div class="media-content">
                <p class="has-text-centered">No packages linked yet</p>
              </div>
            </div>
          </div>



        </card-component>

      </div>

    <project-alarm-detail-component v-if="hasPackages" :project="project" :status="project.status"></project-alarm-detail-component>

    <div class="tile is-parent" >
        <ProjectArmingschemesComponent class="tile is-child" v-if="loaded" :project_id="project.id"></ProjectArmingschemesComponent>
      </div>


      <div  class="tile is-parent">
        <article class="tile is-child" id="scrollto-logs">

          <log-list-component
              v-if="loaded && ($gates.hasAnyPermission(`${project.id}.view logs|view all logs`) || $gates.isSuperUser())"
              :project_id="project.id"
              :project_name="project.name"
          >
          </log-list-component>

        </article>
      </div>


  </div>

<!--        <div id="scrollto-alerts" v-if="this.hasPackages" class="column is-half" >-->
<!--            <alert-list-component v-if="loaded" :project="project"/>-->
<!--        </div>-->


</template>

<script>
import HeroBar from '@/components/HeroBar'
import projects from "@/store/modules/projects.store"
import CardComponent from '@/components/CardComponent'
import AlertListComponent from "../components/package/AlertListComponent";
import EyeIcon from "../components/EyeIcon";
import ProjectArmingschemesComponent from "../components/project/ProjectArmingschemesComponent";
import StatusLineComponent from "@/components/StatusLineComponent";
import LogListComponent from "@/components/package/LogListComponent";
import CameraSnapshotComponent from "@/components/devices/camera/CameraSnapshotComponent";
import ProjectAlarmDetailComponent from "@/components/project/ProjectAlarmDetailComponent";
const GeoJSON = require('geojson');



export default {
  name: 'ProjectDetail',
  components: {
    ProjectAlarmDetailComponent,
    CameraSnapshotComponent,
    StatusLineComponent,
      ProjectArmingschemesComponent, LogListComponent,
      EyeIcon, AlertListComponent, HeroBar, CardComponent },
    data () {
        return {
            project: {},
            hasPackages: true,
            loaded: false


        }
    },
  computed: {
    titleStack () {
      return [
        'Projects'
      ]
    },
      id () {
        return parseInt(this.$route.params.id)
      },

  },

    created() {
        if(!projects.isRegistered) {
            this.$store.registerModule('projects', projects)
            projects.isRegistered = true
        }
    },

    beforeMount() {
        this.fetchData()
    },

    mounted() {
      this.intervalId = window.setInterval(() => {
        this.fetchDataSilent();
      }, 2000);
    },

    beforeDestroy: function () {
      clearInterval(this.intervalId);
    },

  methods: {

        fetchData() {
          let formData = {
            id: this.id,
            params: "include=cameras"
          }
            this.$store.dispatch('projects/fetchProjectDetails', formData)
                .then(response => {
                    this.project = response.data.data
                    if(this.project.packages.length > 0) {
                        this.loaded = true
                        this.$store.dispatch('mapbox/setHeader', {
                            stack: [this.project.name],
                            status: this.project.status})
                        const geo = GeoJSON.parse(this.project.packages, {Point: ['lat', 'long']});
                        //this.$store.dispatch('mapbox/addSource', { id: `project_${this.project.id}`, geo: geo} )
                        this.$store.dispatch('mapbox/drawPackages', { ctx: this, geo: geo, packages: this.project.packages, snap: true} )


                    }
                    else {
                        this.hasPackages = false;
                        this.loaded = true;
                        this.$store.dispatch('mapbox/setHeader', {
                            stack: [this.project.name],
                            status: this.project.status})
                        this.$store.dispatch('mapbox/flyToPoint', [this.project.long, this.project.lat] )
                    }


                })
        },

      fetchDataSilent() {
        let formData = {
          id: this.id,
          params: "include=cameras"
        }
        this.$store.dispatch('projects/fetchProjectDetails', formData)
            .then(response => {
              this.project = response.data.data
              if(this.project.packages.length > 0) {
                this.$store.dispatch('mapbox/setHeader', {
                  stack: [this.project.name],
                  status: this.project.status})
                const geo = GeoJSON.parse(this.project.packages, {Point: ['lat', 'long']});
                //this.$store.dispatch('mapbox/addSource', { id: `project_${this.project.id}`, geo: geo} )
                this.$store.dispatch('mapbox/drawPackages', { ctx: this, geo: geo, packages: this.project.packages, snap: false} )

              }

            })

      },


        isMobile() {
          if (screen.width <= 760) {
            return true
          } else {
            return false
          }
        },

    }
}
</script>

<style lang="scss">

    .package-card-content {
        padding: 1rem ;

    }
    .card__one {
        transition: transform .2s;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: opacity 1s cubic-bezier(.165, .84, .44, 0.5);
            box-shadow: 0 5px 12px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15);
            content: '';
            opacity: 0;
        }

        &:hover,
        &:focus {
            transform: scale3d(1.006, 1.006, 1);

            &::after {
                opacity: 1;
            }
        }
    }

</style>
