<template>


  <div class="tile is-parent pt-0" style="max-width: 95vw">
    <h1>HELLO</h1>
    <b-carousel :indicator-inside="false">
      <b-carousel-item v-for="(cam, i) in cams" :key="i">
        <b-image class="image" :src="cams[i].image"></b-image>
      </b-carousel-item>
      <template #indicators="props">
<!--        <b-image class="al image" :src="cameras[i].image" :title="props.id"></b-image>-->
      </template>
    </b-carousel>

  </div>


</template>

<script>
    import packages from "@/store/modules/packages.store"
    import RefreshButton from "../../RefreshButton";
    import CameraStreamComponent from "./CameraStreamComponent";
    import CardComponent from "../../CardComponent";

    export default {
        name: "CameraSnapshotViewer",
        components: {CardComponent, RefreshButton},
        props: ['cameras', 'project_id'],

        data() {
            return {
                loading: true,
                img: null,
                synced_at: null,
                cams: [],
            }
        },
        created() {
            if(!packages.isRegistered) {
                this.$store.registerModule('packages', packages)
                packages.isRegistered = true
            }
        },

        mounted() {
            this.fetchData()
        },

      computed: {
        getImage(i) {
            return this.cameras[i]?.image
          }

      },

        methods: {

             fetchData() {
               this.cams = this.cameras
               this.cameras.forEach((cam, index) => {
                 this.$store.dispatch('packages/fetchCameraSnapshot', {project_id: this.project_id, camera_id: cam.id, sync: 1})
                   .then(response => {
                     this.cams[index].image =  `data:image/jpeg;base64,${response.img}`;
                   })

               })
            },

            imageModal() {
              const h = this.$createElement
              const vnode = h('p', { class: "image" }, [
                h('img', { attrs: { src: this.img }})
              ])
              this.$buefy.modal.open({
                content: [ vnode ]
              })
            },

            openStreamModal() {
                if(!this.thumb) return
                this.$buefy.modal.open({
                    parent: this,
                    component: CameraStreamComponent,
                    props: {
                        camera_id: this.camera_id,
                        project_id: this.project_id,
                        name: this.name
                    },
                    hasModalCard: false,
                    fullScreen: false,
                    customClass: "px-6 py-6 is-vcentered",
                    trapFocus: false,
                    ariaRole: "dialog",
                    ariaModal: true,
                    scroll: "keep"
                })

            }


        },

    }
</script>

<style scoped>

    /deep/ .b-skeleton {
      height: 100%;
      position: absolute;
      top: 0;
    }
    /* Container needed to position the overlay. Adjust the width as needed */
    .image {
        position: relative;

    }

    /* Make the image to responsive */
    .theimage {
        display: block;
        width: 100%;
        height: 100%;
    }

    /* The overlay effect - lays on top of the container and over the image */
    .overlay {
        position: absolute;
        background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.4); /* Black see-through */
        color: #f1f1f1;
        width: 100%;
        height: 100%;
        transition: .5s ease;
        opacity:0.5;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 0;
        vertical-align: center;
    }

    /* When you mouse over the container, fade in the overlay title */
    .image:hover .overlay {
        opacity: 1;
    }
    .gradient {

    }

</style>
