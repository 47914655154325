<template>
  <card-component class=" has-mobile-sort-spaced pt-3" style="min-height: 88vh">
    <b-table
        :data="data"
        :loading="loading"
        :checkable="false"
        :hoverable="true"

        paginated
        pagination-position="both"
        pagination-size="is-small"
        backend-pagination
        :total="total"
        :per-page="per_page"
        @page-change="onPageChange"

        backend-sorting
        :default-sort="[sort_field, sort_order]"
        @sort="onSort">

      <b-table-column field="icon" class="has-text-centered" width="20" v-slot="props">
        <b-icon
            :icon="props.row.properties.icon"
            :type="props.row.properties.type"
            size="is-small">
        </b-icon>
      </b-table-column>
      <b-table-column field="description" label="description" v-slot="props">

        {{ props.row.description }}
      </b-table-column>

      <b-table-column field="causer_id" label="User" sortable v-slot="props">
        <template v-if="props.row.user">
                <span>
                     <b-icon
                         icon="account"
                         type="grey-dark"
                         size="is-small">
                </b-icon>
                {{ props.row.user.name }}
                </span>
        </template>
      </b-table-column>
      <b-table-column field="created_at" label="Timestamp" sortable v-slot="props">
        <span class="has-text-grey">{{ props.row.created_at | moment("dddd, MMMM Do YYYY, HH:mm:ss ") }}</span>
      </b-table-column>

    </b-table>
  </card-component>
</template>

<script>
import packages from "@/store/modules/packages.store"
import projects from "@/store/modules/projects.store"
import CardComponent from "@/components/CardComponent";
import RefreshButton from '@/components/RefreshButton'

export default {
  name: "LogTableComponent",
  components: {CardComponent, RefreshButton},
  props: ['package_id', 'project_id'],

  data() {
    return {
      data: [],
      loading: true,
      total: 0,
      page: 1,
      per_page: 30,
      sort_field: "",
      sort_order: "asc",
    }
  },

  created() {
    if (!packages.isRegistered) {
      this.$store.registerModule('packages', packages)
      packages.isRegistered = true
    }
    if (!projects.isRegistered) {
      this.$store.registerModule('projects', projects)
      projects.isRegistered = true
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.loading = true

      const params = [
        `page=${this.page}`,
        `per_page=${this.per_page}`,
        `sort=${this.sort_order === "asc" ? "" : "-"}${this.sort_field}`,

      ].join('&')

      if (this.package_id) {
        this.$store.dispatch('packages/fetchPackageLogs', {package_id: this.package_id, params: params})
            .then(response => {
              this.data = response.data.data
              this.total = response.data.meta.total

              this.loading = false
            })
      } else if (this.project_id) {
        this.$store.dispatch('projects/fetchProjectLogs', {project_id: this.project_id, params: params})
            .then(response => {
              this.data = response.data.data
              this.total = response.data.meta.total
              this.loading = false
            })
      }
    },
    onPageChange(page) {
      this.page = page
      this.fetchData()
    },
    /*
    * Handle sort event
    */
    onSort(field, order) {
      this.sort_field = field
      this.sort_order = order
      this.fetchData()
    },


  }


}
</script>

<style scoped>

</style>