<template>
  <div  class="media mb-0 mt-0 pl-2 pr-2 pt-3 pb-3">
    <figure class="media-left">
      <b-image style="height: 100%; width: 100px" :src="`data:image/png;base64,${alert.snapshot}`" ratio="5by3"
      src-fallback="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN8Wg8AAk8BZuQcWLwAAAAASUVORK5CYII=">
      </b-image>
    </figure>
      <div class="media-content">
          <nav class="media-meta level is-mobile" style="margin-bottom: 0">
            <div class="level-left">
              <status-indicator class="level-item" :status="statusLayout(alert.status).color" :pulse="statusLayout(alert.status).pulse" />
              <br>
              <small class="level-item has-text">{{  alert.timestamp | moment("from", "now") }}</small>

            </div>
            <div class="level-right">
              <nav class="media-meta buttons" style="margin-bottom: 0" >

                <!--             <div v-if="alert.status === 'pending' && this.$acl.check('isMeldkamer')">-->
                <!--                 <b-button outlined  icon-left="check" size="is-small" type="is-danger" @click.native="approveAlert(alert)">mark as alarm</b-button>-->
                <!--                 <b-button outlined  icon-left="close" size="is-small" type="is-success" @click.native="denyAlert(alert)">mark as false</b-button>-->
                <!--             </div>-->

                <div v-if="alert.status === 'pending'" class="level-item">
                  <b-tag type="is-warning is-light">pending</b-tag>
                </div>

                <div  v-if="alert.status === 'irrelevant'" class="level-item">
                  <small class="has-text has-text-success-dark">irrelevant</small>
                </div>

                <div  v-if="alert.status === 'relevant'" class="level-item">
                  <small class="has-text has-text-danger-dark">relevant</small>
                </div>
              </nav>

            </div>
          </nav>

          <nav class=" media-meta">
            <b-tag size="is-small" class="mr-2" v-if="project_id">{{ alert.package.name }}</b-tag>
            <small class="has-text">{{  alert.timestamp | moment("D MMM 'YY HH:mm:ss") }}</small>
          </nav>

      </div>

    </div>
</template>

<script>

  import { StatusIndicator } from 'vue-status-indicator';

  export default {
  name: 'AlertItem',
  props: ['alert', 'project_id'],
  components: { StatusIndicator, },

  methods: {

    statusLayout(status){
      if(status === "relevant"){
        return {
          type: 'is-success',
          msg: 'Marked as relevant',
          pulse: false,
            color: "negative"
        }
      } else if(status === "irrelevant"){
        return {
          type: 'is-grey',
          msg: 'Marked as irrelevant',
          pulse: false,
            color: "positive"

        }
      } else if(status === "pending"){
        return {
          type: 'is-warning',
          msg: 'To validate',
          pulse: true,
            color: "negative"
        }
      }
    },

    approveAlert (alert) {
        this.$store.dispatch('packages/updateAlert', {project_id: this.project_id, alert_id: alert.id, formData: { status: 'relevant'}} )
            .then(response => {
                alert.status = 'relevant'
                this.$buefy.snackbar.open({
                    message: 'Marked as relevant',
                    queue: false
                })
            })


        alert.status = 'relevant'
      this.$buefy.snackbar.open({
        message: 'Marked as relevant',
        queue: false
      })
    },
    denyAlert (alert) {
        this.$store.dispatch('packages/updateAlert', {project_id: this.project_id, alert_id: alert.id, formData: { status: 'irrelevant'}} )
            .then(response => {
                alert.status = 'irrelevant'
                this.$buefy.snackbar.open({
                    message: 'Marked as irrelevant',
                    queue: false
                })
            })

    }
  }
}
</script>

<style scoped>
  /deep/ .b-skeleton {
    height: 100%;
    position: absolute;
    top: 0;
  }
</style>
