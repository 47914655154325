<template>
  <div>
    <log-table-component :package_id="this.$route.params.id"/>
  </div>

</template>

<script>
import TitleBar from "../components/TitleBar";
import LogTableComponent from "../components/package/LogTableComponent";

export default {
  name: 'PackageLogs',
  components: {LogTableComponent, TitleBar},

  mounted() {
    this.$store.dispatch('mapbox/setHeader', {
      stack: [
        this.$route.params.project_name,
        this.$route.params.package_name,
        'Logs'
      ]
    })
  }

}
</script>

<style lang="scss">


</style>