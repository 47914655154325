<template>
  <div class="tile is-ancestor is-vertical ">

    <div v-if="the_package.type !== 'anpr'" class="tile is-parent pb-0">
      <div class="tile is-child">

        <div class=" level is-mobile mb-2 pl-1">
          <div class=" level-left">
            <p class="level-item has-text-left mb-0 py-0">
              <b-icon size="is-small" class="mr-2" icon="eye"></b-icon>
              <span style="margin-right:  0.7rem;" class="title is-6">Recent Snapshot</span>
            </p>
          </div>
          <div v-if="the_package" class="level-right">
            <p class="level-item py-0">
              <small class="is-5">{{ this.the_package.cameras.length }} cameras</small>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!--    <camera-snapshot-viewer :cameras="the_package.cameras" :project_id="the_package.project.id">-->

    <!--    </camera-snapshot-viewer>-->

    <div v-if="the_package.type !== 'anpr'" class="tile is-parent pt-0" style="max-width: 95vw">
      <b-carousel-list v-permission:any="`${the_package.project.id}.view camera|view all cameras`" class="tile is-child"
                       v-if="the_package" :data="this.the_package.cameras" :items-to-show="isMobile() ? 1.1 : 2.4">
        <template slot="item" slot-scope="camera">

          <camera-snapshot-component :project_id="the_package.project.id" :camera_id="camera.id" :name="camera.name"
                                     :thumb="true"></camera-snapshot-component>

        </template>
      </b-carousel-list>
      <div class="tile is-child card" v-else>

        <b-skeleton height="15vw"></b-skeleton>

      </div>
    </div>

    <div v-if="the_package" class="tile is-vertical" id="scrollto-alarm">

      <a-i-box-detail-component v-for="aibox in the_package.aiboxes"
                                :aibox="aibox"
                                :status="the_package.status"
                                :project="the_package.project">

      </a-i-box-detail-component>

      <alarm-detail-component v-for="alarm in the_package.alarms"
                              :alarm="alarm"
                              :status="the_package.status"
                              :project="the_package.project">

      </alarm-detail-component>
      <div v-if="the_package.alarms.length === 0 && the_package.aiboxes.length === 0" class="tile">
        <article class="tile is-parent">
          <card-component class="tile is-child" title="Alarm" icon="lock">
            <p class="has-text-centered has-text-grey-dark">No alarms linked</p>
          </card-component>
        </article>
      </div>

      <div class="tile is-parent ">
        <div class="tile is-child" id="scrollto-outputs">
          <div class=" level is-mobile mb-2 pl-1">
            <div class=" level-left">
              <p class="level-item has-text-left mb-0 py-0">
                <b-icon size="is-small" class="mr-2" icon="toggle-switch"></b-icon>
                <span style="margin-right:  0.7rem;" class="title is-6">Switchable items</span>
              </p>
            </div>
            <div class="level-right">
              <a v-permission:any="`${the_package.project.id}.update outputs|manage all outputs`"
                 class="level-item py-0"
                 @click.prevent="$router.push({ name:'project.outputs', params: {project_id: the_package.project.id}})">
                <span class="is-5 pr-2">view all</span>
                <b-icon size="is-small" icon="arrow-right"></b-icon>
              </a>
            </div>
          </div>

          <div v-if="the_package" class="columns is-multiline">
            <output-blocks-component v-for="output in the_package.outputs"
                                     :key="output.id"
                                     :output="output"
                                     :project_id="the_package.project.id">

            </output-blocks-component>

            <div v-if="the_package.outputs.length === 0" class="column has-text-centered">

              <p class="has-text-centered">No switchable items found</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="tile is-parent">
      <article class="tile is-child" id="scrollto-logs">

        <log-list-component
          v-if="the_package && ($gates.hasAnyPermission(`${the_package.project.id}.view logs|view all logs`) || $gates.isSuperUser())"
          :package_id="the_package.id"
          :project_name="the_package.project.name"
          :package_name="the_package.name"
        >

        </log-list-component>

      </article>
    </div>


    <div class="tile is-parent">
      <article class="tile is-child">


      </article>
    </div>


  </div>

</template>

<script>
import HeroBar from '@/components/HeroBar'
import packages from "@/store/modules/packages.store"
import CardComponent from '@/components/CardComponent'
import AlertListComponent from "../components/package/AlertListComponent";
import AlarmDetailComponent from "../components/package/AlarmDetailComponent";
import EyeIcon from "../components/EyeIcon";
import ProjectFormComponent from "../components/project/ProjectFormComponent";
import RelocatePackageComponent from "../components/package/RelocatePackageComponent";
import LogTableComponent from "../components/package/LogTableComponent";
import LogListComponent from "../components/package/LogListComponent";
import CameraCarouselComponent from "../components/package/CameraCarouselComponent";
import CameraSnapshotComponent from "../components/devices/camera/CameraSnapshotComponent";
import OutputBlocksComponent from "@/components/package/OutputBlocksComponent";
import CameraSnapshotViewer from "@/components/devices/camera/CameraSnapshotViewer";
import AIBoxDetailComponent from "@/components/package/AIBoxDetailComponent";

const GeoJSON = require('geojson');


export default {
  name: 'PackageDetail',
  components: {
    AIBoxDetailComponent,
    CameraSnapshotViewer,
    OutputBlocksComponent,
    CameraSnapshotComponent,
    CameraCarouselComponent,
    LogTableComponent,
    LogListComponent,
    EyeIcon, AlarmDetailComponent, AlertListComponent, HeroBar, CardComponent
  },
  data() {
    return {
      the_package: null,
      isArmed: false,
      isArming: false,
      isDisarming: false,

    }
  },
  computed: {
    titleStack() {
      return [
        'Packages'
      ]
    },
    id() {
      return parseInt(this.$route.params.id)
    },

    //...mapGetters({
    //    currentSite: 'sites/getSiteById'
    //})
  },
  watch: {
    $route(to, from) {
      this.fetchData()
    }
  },


  created() {
    if (!packages.isRegistered) {
      this.$store.registerModule('packages', packages)
      packages.isRegistered = true
    }
  },

  beforeMount() {
    this.fetchData()
  },

  mounted() {
    this.intervalId = window.setInterval(() => {
      this.fetchDataSilent();
    }, 2500);

  },

  beforeDestroy: function () {
    clearInterval(this.intervalId);
  },

  methods: {

    info(value) {
      this.test = value
    },

    fetchData() {

      let formData = {
        id: this.id,
        params: "include=project,alarms,cameras,outputs,aiboxes.partitions.sensors"
      }

      this.$store.dispatch('packages/fetchPackageDetails', formData)
        .then(response => {
          this.the_package = response.data.data
          this.$store.dispatch('mapbox/setHeader', {
            stack: this.the_package.project ? [this.the_package.project.name, this.the_package.name] : [this.the_package.name],
            status: this.the_package.status
          })
          const geo = GeoJSON.parse(this.the_package, {Point: ['lat', 'long']});
          this.$store.dispatch('mapbox/focusOnPackage', {ctx: this, geo: geo, package: this.the_package})
        })
    },

    fetchDataSilent() {
      let formData = {
        id: this.id,
        params: "include=project,alarms,cameras,outputs,aiboxes.partitions.sensors"
      }

      this.$store.dispatch('packages/fetchPackageDetails', formData)
        .then(response => {
          this.the_package = response.data.data
          this.$store.dispatch('mapbox/setHeader', {
            stack: this.the_package.project ? [this.the_package.project.name, this.the_package.name] : [this.the_package.name],
            status: this.the_package.status
          })
        })
    },

    isMobile() {
      if (screen.width <= 760) {
        return true
      } else {
        return false
      }
    },


  }
}
</script>

<style lang="scss">

.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-right: 13px;

  .scrollablecard {
    flex: 0 0 auto;
  }
}

.carousel-list.has-shadow {
  box-shadow: none;
}


</style>
