<template>
    <card-component :title="`Project ${action} results`"
                    icon="check"
                    :is-scrollable="false"
                    style="min-width: 350px">

        <div class="media-list">

            <div  class="media" v-for="update in this.package_updates">

                <div class="media-content">
                    <div class="content">
                        <nav class="level is-mobile">
                            <div class="level-left">
                                <div class="level-item">
                                    <div>
                                      <div class="text has-text-weight-medium">{{ update.package }}
                                          <small class="has-text-grey"> | {{ action }}</small>
                                      </div>
                                    </div>
                                </div>

                            </div>
                            <div class="level-right">
                              <b-tag v-if="update.status === 'success'" type="is-success is-light">Succesful</b-tag>
                              <b-tag v-else-if="update.status === 'unreachable'" type="is-danger is-light">Unreachable</b-tag>
                              <b-tag v-else type="is-danger is-light">Failed</b-tag>
                            </div>
                        </nav>

                    </div>
                  <div v-if="update.message" class="has-text-centered">
                    <b-tag type="is-warning is-light">
                      <small>{{ update.message }}</small>
                    </b-tag>
                  </div>
                </div>
            </div>

          <div v-if="this.package_updates.length === 0" class="media">
            <div class="media-content">
              <div class="content">
                <b-icon type="is-success" size="is-small" icon="check-circle"></b-icon>
                <span>
                    No packages were {{action}}ed.
                  </span>
              </div>
            </div>
          </div>


        </div>
        <hr>
        <p class="text is-size-7">
            If a package was not reachable on the moment of {{ action }} action, <br> you need to make sure to {{ action }}  it manually once it is back online.
        </p>
        <hr>
        <b-field horizontal >
            <b-field grouped position="is-right">
                <div class="control">
                    <b-button type="is-primary is-outlined" @click="this.$parent.close">Close</b-button>
                </div>
            </b-field>

        </b-field>



    </card-component>
</template>

<script>
    import CardComponent from "../../CardComponent";
    export default {
        name: "ProjectArmDisarmStatusComponent",
        components: {CardComponent},
        props: ['package_updates', 'action']
    }
</script>

<style scoped>

</style>