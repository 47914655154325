<template>
    <card-component :is-scrollable="false" :has-button-slot="true" title="Alarm Schemas" icon="lock-clock">
        <div slot="button" class="buttons" v-permission:any="`${project_id}.view schemas|manage all schemas|${project_id}.view exceptions|manage all exceptions`">
            <b-button @click="$router.push({ name:'project.schemes', params: {project_id: project_id} })" type="is-grey" size="is-small" icon-left="eye">
                View schemes
            </b-button>
        </div>
        <b-loading :is-full-page="false" :active="loading"></b-loading>
        <div v-if="$gates.hasAnyPermission(`${project_id}.view schemas|manage all schemas`) || $gates.isSuperUser()" class="media-list">
            <div class="media" v-for="pack in availablePartitions">
                <div class="media-content">
                    <p class="title is-6 mb-3">{{pack[0]}}</p>
                    <div class="level is-mobile pb-2 my-0" v-for="partition in pack[1]">
                        <div class="level-left">
                            <p class="level-item"> {{partition.name}}</p>
                        </div>
                        <div class="level-left">
                            <b-field>
                                <b-select v-model="partition.armingscheme_id"
                                          @input="linkPartition(partition)"
                                          :disabled="!($gates.hasAnyPermission(`${project_id}.update schemas|manage all schemas`) || $gates.isSuperUser())"
                                          placeholder="Schema disabled"
                                          icon="lock-clock"
                                          size="is-small">
                                    <option :value="null">Disable</option>
                                    <option v-for="scheme in schemes" :value="scheme.id">{{scheme.name}}</option>
                                </b-select>
                            </b-field>
                        </div>

                    </div>

                </div>
            </div>

            <div v-if="availablePartitions.length === 0" class="media">
              <div class="media-content">
                <p class="has-text-centered">No schemas created yet</p>
              </div>
            </div>
        </div>
        <div v-else>
          <small>
            You don't have access to view the alarm schemes or exceptions.
          </small>
        </div>

    </card-component>
</template>

<script>
import alarm from "../../store/modules/alarm.store";
import CardComponent from "../CardComponent";
import SchemeUpdateStatusComponent from "../package/schemes/SchemeUpdateStatusComponent";

export default {
    name: "ProjectArmingschemesComponent",
    components: {CardComponent},
    props: ["project_id"],

    data() { return {
       package_partitions: [],
        loading: false,

    }},
    created() {
        if(!alarm.isRegistered) {
            this.$store.registerModule('alarm', alarm)
            alarm.isRegistered = true
        }
    },
    beforeMount() {
        this.fetchData()
    },
    computed: {
        schemes() {
            return this.$store.getters['alarm/schemesForProject'](this.project_id)
        },
        availablePartitions() {
            return  Array.from(this.groupBy(this.$store.getters['alarm/allPartitions'], partition => partition.package.name));
        }
    },
    methods: {

       groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    },

    fetchData() {
        let payload = {
            project_id: this.project_id,
            params: "include=partitions"
        }
        this.$store.dispatch('alarm/fetchProjectPartitions', payload).then(response => {
            this.package_partitions = Array.from(this.groupBy(response.data, partition => partition.package.name));

        })
        this.$store.dispatch('alarm/fetchSchemes', payload)
            .then(response => {
            })

    },
        updateResultModal(part_upd) {
            this.$buefy.modal.open({
                parent: this,
                component: SchemeUpdateStatusComponent,
                props: {
                    partition_updates: part_upd,
                },
                hasModalCard: true,
                trapFocus: true,
                events: {
                    update: () =>  {
                        this.$emit('update')
                    }
                }
            })

        },
    linkPartition(partition) {
           this.loading = true
        this.$store.dispatch('alarm/linkSchema', {
            "project_id": this.project_id,
            "partition_id": partition.id,
            "data": { armingscheme_id: partition.armingscheme_id}
        })
            .then(response => {
                this.loading = false
                this.updateResultModal(response.data.partition_updates)
            }).catch(error => {
                this.loading = false
                this.$buefy.toast.open({
                    message: 'Scheme could not be updated: ' + error,
                    type: 'is-danger'
                })
        })

   }
    }


}
</script>

<style scoped>

</style>
