<template>
    <card-component title="Relocate Package"
                    icon="map-marker-radius"
                    :is-scrollable="false">
        <form>

            <b-notification type="is-info" aria-close-label="Close notification">
                Drag the blue marker to the location of the package.
            </b-notification>

            <mapbox style="height: 250px" ref="relocatepackagemap" :access-token="accessToken" :map-options="mapStyle"
                    :geolocate-control="{
                      show: false,
                      position: 'top-right',
                    }"
                    :nav-control="{
                      show: false,
                      position: 'top-right',
                    }"
                    @map-init="mapInitialized">

            </mapbox>

            <hr>

            <b-field label="Location" horizontal>
                <b-field>
                    <b-input v-model="package.long" placeholder="Longitude" name="long" required @input="updateLocation"/>
                </b-field>
                <b-field>
                    <b-input v-model="package.lat" placeholder="Latitude" name="lat" required @input="updateLocation" />
                </b-field>
            </b-field>

            <b-field horizontal >
                <b-field grouped position="is-right">
                    <div class="control">
                      <b-button outlined :disabled="!this.orig_package.project" @click="locationFromProject" type="is-primary">Copy location from project</b-button>
                    </div>
                    <div class="control">
                        <b-button  :disabled="!this.package.id" @click="submit" type="is-primary">Relocate</b-button>
                    </div>
                </b-field>
            </b-field>

        </form>

    </card-component>
</template>

<script>
    import packages from "@/store/modules/packages.store"
    import CardComponent from "../CardComponent";

    import Mapbox from 'mapbox-gl-vue'
    import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
    import bbox from '@turf/bbox';

    export default {
        name: "PackageFormComponent",
        components: { CardComponent, Mapbox },
        props: {
            orig_package: {
                default: null
            },
        },


        data() {
            return {
                package: {
                    id: null,
                    name: "",
                    site_id: null,
                    keycode: "",
                    long: 4.64,
                    lat: 50.44,
                    has_battery: false,
                },
                accessToken: 'pk.eyJ1IjoibHVja2FzZGMiLCJhIjoiY2sxdWR6d3B5MDkwOTNpcGFuNXh3dGhzNSJ9.nBYgv6LPBGFGDx56p8mRQQ',
                mapStyle: {
                    style: 'mapbox://styles/luckasdc/ckf29lnav0mm319qvg7u55h7l',
                    container: "relocatepackagemap"
                },
                themap: {},
                marker: {},


            }
        },

        computed: {
            creating () {
                return this.id === null
            },

        },
        created() {
            if(!packages.isRegistered) {
                this.$store.registerModule('packages', packages)
                packages.isRegistered = true
            }
        },

        methods: {
            initValues() {

            },
            mapInitialized: function (map) {
                this.themap = map

                this.marker = new mapboxgl.Marker({
                    draggable: true
                })
                    .setLngLat([this.orig_package.long, this.orig_package.lat])
                    .addTo(this.themap);

                this.marker.on('dragend', this.dragged)

                this.themap.jumpTo({center: [this.orig_package.long, this.orig_package.lat], zoom: 16 });
            },

            dragged(marker) {
                let lngLat = marker.target._lngLat
                this.package.long = lngLat.lng
                this.package.lat = lngLat.lat
                this.package.id = this.orig_package.id
            },

            updateLocation() {
              this.package.id = this.orig_package.id
              this.marker.setLngLat([this.package.long, this.package.lat])
                    .addTo(this.themap);
              this.themap.jumpTo({center: [this.package.long, this.package.lat], zoom: 16 });

            },

            locationFromProject() {
              this.package.id = this.orig_package.id
              this.marker.setLngLat([this.orig_package.project.long, this.orig_package.project.lat])
                  .addTo(this.themap);
              this.themap.jumpTo({center: [this.orig_package.project.long, this.orig_package.project.lat], zoom: 16 });
              this.package.long = this.orig_package.project.long
              this.package.lat = this.orig_package.project.lat
            },

            submit() {
                this.$store.dispatch('packages/updatePackage', { package_id: this.orig_package.id, formData: {long: this.package.long, lat: this.package.lat }})
                    .then(response => {
                        this.$parent.close()
                        this.$router.push({ name:'project.detail', params: {id: this.orig_package.project.id}})
                        this.$buefy.toast.open({
                            message: 'Package relocated',
                            type: 'is-success'
                        })

                    }).catch(err => {
                    this.$buefy.toast.open({
                        message: err,
                        type: 'is-danger'
                    })
                })
            }

        }

    }
</script>

<style scoped>

</style>