<template>
  <div class="tile is-ancestor is-vertical">

    <div class="tile is-parent" id="scrollto-alerts">
      <alert-list-component v-if="the_package.type === 'v1' && ($gates.hasAnyPermission(`${the_package.project.id}.view alerts|view all alerts`) || $gates.isSuperUser())"
                            class="tile is-child"   :package="the_package">
      </alert-list-component>

      <incident-list-component v-else-if="the_package.type === 'v2' && ($gates.hasAnyPermission(`${the_package.project.id}.view alerts|view all alerts`) || $gates.isSuperUser())"
                                class="tile is-child" :project="the_package.project" :package="the_package">

      </incident-list-component>
    </div>


    <div class="tile is-parent">
        <div v-can="`manage all packages`"class="buttons tile is-child">
          <b-button icon-left="map-marker" @click="relocatePackage" >Change location</b-button>
          <b-button icon-left="pencil" @click="$router.push({ name:'package.edit', params: {id: the_package.id}})" >Edit Package</b-button>
        </div>
    </div>

  </div>

</template>

<script>
import packages from "@/store/modules/packages.store"
import AlertListComponent from "../components/package/AlertListComponent";
import RelocatePackageComponent from "../components/package/RelocatePackageComponent";
import IncidentListComponent from "@/components/package/IncidentListComponent";
const GeoJSON = require('geojson');


export default {
  name: 'PackageDetailSide',
  components: {
    IncidentListComponent,
 AlertListComponent, },
    data () {
        return {
            the_package: null,
        }
    },
  computed: {
    titleStack () {
      return [
        'Packages'
      ]
    },
      id () {
        return parseInt(this.$route.params.id)
      },

      //...mapGetters({
      //    currentSite: 'sites/getSiteById'
      //})
  },
    watch: {
        $route(to, from) {
            this.fetchData()
        }
    },


    created() {
        if(!packages.isRegistered) {
            this.$store.registerModule('packages', packages)
            packages.isRegistered = true
        }
    },

    beforeMount() {
        this.fetchData()
    },

    methods: {

        fetchData() {

          let formData = {
              id: this.id,
              params: "include=project"
          }

            this.$store.dispatch('packages/fetchPackageDetails', formData)
                .then(response => {
                    this.the_package = response.data.data
                })
        },


      relocatePackage() {
        this.$buefy.modal.open({
          parent: this,
          component: RelocatePackageComponent,
          props: {
            orig_package: this.the_package
          },
          hasModalCard: true,
          trapFocus: false,
          ariaRole: "dialog",
          ariaModal: true,
          scroll: "keep"
        })
      }



    }
}
</script>

<style lang="scss">

    .package-card-content {
        padding: 1rem ;

    }
    .card__one {
        transition: transform .2s;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: opacity 1s cubic-bezier(.165, .84, .44, 0.5);
            box-shadow: 0 5px 12px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15);
            content: '';
            opacity: 0;
        }

        &:hover,
        &:focus {
            transform: scale3d(1.006, 1.006, 1);

            &::after {
                opacity: 1;
            }
        }
    }

</style>
