<template>
  <article class="column is-full-mobile is-half-desktop is-half-widescreen is-one-third-fullhd">
    <card-component class="is-card-widget">
      <b-loading :active="loading" :is-full-page="false"></b-loading>
      <div class="level is-mobile mb-2">
        <div class="level-left">

          <div class="level-item has-text-left">
            <b-icon  class=""  :icon="output.icon ? output.icon : 'power-standby'" type="is-info"></b-icon>
            <span class="title is-6  pl-2">{{ output.name }}</span>
          </div>

        </div>
        <div  class="level-right">
          <div class="level-item">
            <b-field  v-if="$gates.hasAnyPermission(`${project_id}.toggle outputs|toggle all outputs`) || $gates.isSuperUser()" class="pt-1 mr-0">
              <b-switch class="mr-0" true-value="1" false-value="0" v-model="modus" @input="toggle" type="is-info" size="is-small"></b-switch>
            </b-field>
            <b-field  v-else class="pt-1 mr-0">
              <b-switch disabled class="mr-0" true-value="1" false-value="0" v-model="modus" @input="toggle" type="is-info" size="is-small"></b-switch>
            </b-field>
          </div>
        </div>
      </div>

      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <small>Mode:</small>
          </div>
        </div>

      <div class="level-right">
        <b-tag class="level-item" type="is-info is-light">
          <b-icon class="mr-1" size="is-small" :icon="modeName(output.mode).icon"></b-icon>
          {{ modeName(output.mode).name }}
        </b-tag>
      </div>
      </div>

    </card-component>
  </article>

</template>

<script>
import CardComponent from "@/components/CardComponent";
export default {
  name: "OutputBlocksComponent",
  components: {CardComponent, },
  props: ["output", "project_id"],
  data() {
    return {
      loading: false,
      modus: null,
      modeList: [
        {icon: 'toggle-switch', value: 0, name: 'Manual'},
        {icon: 'lock', value: 1, name: 'While Armed'},
        {icon: 'lock-alert', value: 2, name: 'When Triggered'},
        {icon: 'theme-light-dark', value: 3, name: 'Nightmode',},
        {icon: 'lock-clock', value: 4, name: 'Follow Schema'},

      ],
    }
  },
  beforeMount() {
    this.modus = this.output.output
  },

  watch: {
    output: function () {
      this.modus = this.output.output
    }
  },

  methods: {
    toggle(event) {
      if(event === "1") {
        this.activate_output()
      } else{
        this.deactivate_output()
      }
    },
    modeName(id) {
      return this.modeList.filter(mode => mode.value === id)[0]
    },
    activate_output() {
      let currentstate = this.output.output
      let payload = {
        project_id: this.project_id,
        output_id: this.output.id
      }
      this.loading = true
      this.$store.dispatch('alarm/activateOutput', payload )
          .then(response => {
            let message = JSON.parse(JSON.stringify(response.data.message))
            this.loading = false
            this.modus = "1"
            this.$buefy.toast.open({
              message: message,
              type: 'is-success'
            })

          }).catch((error) => {
            this.loading = false
            this.modus = currentstate

            this.$buefy.toast.open({
              message: error.response.data.message,
              type: 'is-danger'
            })

      })

    },
    deactivate_output() {
      let currentstate = this.output.output
      let payload = {
        project_id: this.project_id,
        output_id: this.output.id
      }
      this.loading = true
      this.$store.dispatch('alarm/deactivateOutput', payload )
          .then(response => {
            let message = JSON.parse(JSON.stringify(response.data.message))
            this.loading = false
            this.modus = "0"
            this.$buefy.toast.open({
              message: message,
              type: 'is-success'
            })

          }).catch((error) => {
            this.loading = false
            this.modus = currentstate

            this.$buefy.toast.open({
                  message: error.response.data.message,
                  type: 'is-danger'
                })

          })


    },

  }
}
</script>

<style scoped>

</style>
