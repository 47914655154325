<template>
  <div class="tile is-vertical">



    <div class="tile is-parent pb-0">
      <div class="tile is-child">

        <div class=" level is-mobile mb-2 pl-1">
          <div class=" level-left">
            <p class="level-item has-text-left mb-0 py-0">
              <b-icon size="is-small" class="mr-2" icon="eye"></b-icon>
              <span style="margin-right:  0.7rem;" class="title is-6">Recent Snapshot</span>
            </p>
          </div>
          <div class="level-right">
            <p class="level-item py-0">
              <small class="is-5">5 cameras</small>
            </p>
          </div>
        </div>
      </div>
    </div>


    <div class="tile is-parent pt-0" style="max-width: 95vw">
      <b-carousel-list class="tile is-child" v-if="project" :data="this.project.cameras"  :items-to-show="2.4">
        <template slot="item" slot-scope="camera">

          <camera-snapshot-component  :project_id="id" :camera_id="camera.id" :name="camera.name" :thumb="true" ></camera-snapshot-component>

        </template>
      </b-carousel-list>
      <div class="tile is-child card" v-else>

        <b-skeleton height="15vw"></b-skeleton>

      </div>
    </div>

<!--      <div class="tile is-parent" >-->
<!--        <card-component class="tile is-child" :is-scrollable="false" :has-button-slot="true" title="Towers & Sets" icon="eye">-->
<!--          <div v-if="$acl.check('isTechnician')" slot="button" class="buttons">-->
<!--            <b-button @click="attachPackage" type="is-grey" size="is-small" icon-left="plus-circle">-->
<!--              Attach package-->
<!--            </b-button>-->
<!--          </div>-->

<!--          <div class="media-list">-->
<!--            <div class="media" v-for="a_package in project.packages"  style="cursor:pointer" @click="$router.push({ name:'package.detail', params: {project_id: project.id, id: a_package.id} })">-->
<!--              <div class="media-left">-->
<!--                <eye-icon :status="a_package.status" type="project" :has-text="false"/>-->
<!--              </div>-->
<!--              <div class="media-content">-->
<!--                <p class="title is-5">{{ a_package.name }}</p>-->
<!--                &lt;!&ndash;                            <p class="subtitle is-6">{{ project.address }} {{ project.nr }} {{ project.town }}</p>&ndash;&gt;-->
<!--                <status-component :status="a_package.status"/>-->

<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--        </card-component>-->

<!--      </div>-->

      <div class="tile is-parent" >
        <div class="tile is-child" id="scrollto-alerts">
          <alert-list-component  v-if="loaded" :project="project"/>
        </div>
      </div>


      <div class="tile is-parent">
        <article class="tile is-child">


        </article>
      </div>


  </div>

<!--        <div id="scrollto-alerts" v-if="this.hasPackages" class="column is-half" >-->
<!--            <alert-list-component v-if="loaded" :project="project"/>-->
<!--        </div>-->


</template>

<script>
import HeroBar from '@/components/HeroBar'
import projects from "@/store/modules/projects.store"
import CardComponent from '@/components/CardComponent'

import LinkPackageComponent from "../components/package/LinkPackageComponent";
import AlertListComponent from "../components/package/AlertListComponent";
import EyeIcon from "../components/EyeIcon";
import ProjectFormComponent from "../components/project/ProjectFormComponent";
import ProjectArmingschemesComponent from "../components/project/ProjectArmingschemesComponent";
import CameraSnapshotComponent from "@/components/devices/camera/CameraSnapshotComponent";
const GeoJSON = require('geojson');



export default {
  name: 'ProjectDetail',
  components: {
    CameraSnapshotComponent,
      ProjectArmingschemesComponent,
      EyeIcon, AlertListComponent, HeroBar, CardComponent },
    data () {
        return {
            project: {},
            hasPackages: true,
            loaded: false


        }
    },
  computed: {
    titleStack () {
      return [
        'Projects'
      ]
    },
      id () {
        return parseInt(this.$route.params.id)
      },

      //...mapGetters({
      //    currentProject: 'projects/getProjectById'
      //})
  },

    created() {
        if(!projects.isRegistered) {
            this.$store.registerModule('projects', projects)
            projects.isRegistered = true
        }
    },

    beforeMount() {
        this.fetchData()

    },

    methods: {

        fetchData() {
          let formData = {
            id: this.id,
            params: "include=cameras"
          }
            this.$store.dispatch('projects/fetchProjectDetails', formData)
                .then(response => {
                    this.project = response.data.data
                    if(this.project.packages.length > 0) {
                        this.loaded = true
                        this.$store.dispatch('mapbox/setHeader', {
                            stack: [this.project.name],
                            status: this.project.status})
                        const geo = GeoJSON.parse(this.project.packages, {Point: ['lat', 'long']});
                        //this.$store.dispatch('mapbox/addSource', { id: `project_${this.project.id}`, geo: geo} )
                        this.$store.dispatch('mapbox/drawPackages', { ctx: this, geo: geo, packages: this.project.packages, snap: true} )


                    }
                    else {
                        this.hasPackages = false;
                        this.loaded = true;
                        this.$store.dispatch('mapbox/setHeader', {
                            stack: [this.project.name],
                            status: this.project.status})
                        this.$store.dispatch('mapbox/flyToPoint', [this.project.long, this.project.lat] )
                    }


                })
        },

        attachPackage() {
            let thisIns = this
            this.$buefy.modal.open({
                parent: this,
                component: LinkPackageComponent,
                props: {
                    project: this.project
                },
                ariaRole: "dialog",
                ariaModal: true,
                hasModalCard: true,
                customClass: 'custom-class custom-class-2',
                trapFocus: false,
                onCancel: this.resetStack,

            })
        },
        deleteModal() {
            this.$buefy.dialog.confirm({
                title: 'Removing Project',
                message: 'Are you sure you want to <b>remove</b> this project? This action cannot be undone. Linked packages' +
                    ' will be unlinked and alerts will be deleted.',
                confirmText: 'Remove Project',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.deleteProject()

                }
            })
        },

        deleteProject() {
            this.$store.dispatch('projects/deleteProject', this.project.id)
                .then(response => {
                    this.$buefy.toast.open('Project deleted')
                    this.$router.push({ name:'projects'})

                })

        },
        formModal() {
            this.$buefy.modal.open({
                parent: this,
                component: ProjectFormComponent,
                props: {
                    id: this.project.id
                },
                hasModalCard: true,
                trapFocus: false,
                ariaRole: "dialog",
                ariaModal: true,
                scroll: "keep"
            })
        }

    }
}
</script>

<style lang="scss">

    .package-card-content {
        padding: 1rem ;

    }
    .card__one {
        transition: transform .2s;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: opacity 1s cubic-bezier(.165, .84, .44, 0.5);
            box-shadow: 0 5px 12px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15);
            content: '';
            opacity: 0;
        }

        &:hover,
        &:focus {
            transform: scale3d(1.006, 1.006, 1);

            &::after {
                opacity: 1;
            }
        }
    }

</style>
