<template>
  <div class="tile is-parent">
    <div class="tile is-child">
      <div class="columns is-multiline">
        <article class="column is-full-desktop is-half-fullhd">
          <card-component :has-footer-slot="true">
            <div class="media-list">
              <div class="media">
                <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
                <div v-if="status" class="media-content">
                  <div class="level is-mobile mb-2">
                    <div class="level-left">
                      <!--                            <div class="level-item" style="vertical-align: top">-->
                      <!--                                <status-indicator style="margin-right:  0.5rem; &#45;&#45;status-indicator-size: 15px;" :status="projectStatus(partition).indicator" :pulse="projectStatus(partition).pulse" />-->
                      <!--                            </div>-->

                      <div class="level-item has-text-left" style="flex-shrink: 1">
                        <b-icon
                            class="mb-3 mr-3"
                            size="is-large"
                            :icon="projectStatus(status).icon"
                            :type="projectStatus(status).color"
                        ></b-icon>

                        <div>
                          <p class="mb-0 py-0">
                          <span
                              style="margin-right: 0.7rem"
                              class="title is-5"
                          >{{ project.name }}</span
                          >
                          </p>
                          <p class="py-0">
                            <small
                                v-bind:class="projectStatus(status).type"
                                class="is-5"
                            >{{ projectStatus(status).msg }}</small
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="level-right">
                      <div class="level-item">
                        <b-field class="level-item">
                          <p class="control">
                            <b-button
                                rounded
                                @click="confirmArm()"
                                v-permission:any.has.disabled="`${project.id}.arm partitions|arm all partitions`"
                                size="is-small"
                                :type="status.alarm_status === 'armed' ? 'is-danger' : 'is-grey'"
                                icon-left="lock"
                            ></b-button
                            >
                          </p>
                          <p class="control">
                            <b-button
                                rounded
                                @click="confirmDisarm()"
                                v-permission:any.has.disabled="`${project.id}.disarm partitions|disarm all partitions`"
                                size="is-small"
                                :type="status.alarm_status === 'armed' ? 'is-grey' : 'is-success'"
                                icon-left="lock-open-variant"
                            ></b-button
                            >
                          </p>
                        </b-field>
                      </div>
                    </div>
                  </div>

                  <div class="">
                    <div
                        v-for="a_package in project.packages"
                        class="level is-mobile mt-2 mb-4"
                    >
                      <div class="level-left">
                        <div class="level-item">
                          <status-indicator
                              style="display: inline-block; margin-right: 0.4rem"
                              :status="packageStatus(a_package.status).indicator"
                              :pulse="packageStatus(a_package.status).pulse"
                          />
                          <small class="hast-text">{{ a_package.name }}</small>
                        </div>
                      </div>
                      <div class="level-right">
                        <div class="level-item">
                          <b-tag :type="packageStatus(a_package.status).type">{{
                              packageStatus(a_package.status).msg
                            }}
                          </b-tag>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card-component>
        </article>


        <article class="column">
          <card-component :has-footer-slot="true">
            <div class="media-list">
              <div class="media">
                <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
                <div class="media-content" v-if="status">
                  <div class="level is-mobile mb-2">
                    <div class="level-left">
                      <!--                            <div class="level-item" style="vertical-align: top">-->
                      <!--                                <status-indicator style="margin-right:  0.5rem; &#45;&#45;status-indicator-size: 15px;" :status="partitionStatus(partition).indicator" :pulse="partitionStatus(partition).pulse" />-->
                      <!--                            </div>-->
                      <div class="level-item has-text-left">
                        <b-icon
                            v-if="status.power_timestamp === 'powered'"
                            class="mb-3"
                            size="is-large"
                            icon="power-plug"
                            :type="packagePowerStatus(status).color"
                        ></b-icon>

                        <b-icon
                            v-if="status.power_status === 'powered'"
                            class="mb-3"
                            size="is-large"
                            icon="power-plug"
                            :type="packagePowerStatus(status).color"
                        ></b-icon>
                        <b-icon
                            v-else-if="status.power_status === 'no power'"
                            class="mb-3"
                            size="is-large"
                            icon="power-plug-off"
                            :type="packagePowerStatus(status).color"
                        ></b-icon>
                        <b-icon
                            v-else
                            class="mb-3"
                            size="is-large"
                            icon="power-plug-outline"
                            type="is-grey"
                        ></b-icon>
                      </div>
                      <div class="level-item">
                        <div>
                          <p class="mb-0 py-0">
                          <span
                              v-if="status.power_status === 'powered'"
                              style="margin-right: 0.7rem"
                              class="title is-5"
                          >Powered</span
                          >
                            <span
                                v-else-if="status.power_status === 'no power'"
                                style="margin-right: 0.7rem"
                                class="title is-5"
                            >No power</span
                            >
                            <span
                                v-else
                                style="margin-right: 0.7rem"
                                class="title is-5"
                            >Power unknown</span
                            >
                          </p>
                          <p class="py-0">
                            <small class="is-5 has-text-grey-dark"
                            >Last checked
                              {{
                                status.power_timestamp | moment("from", "now")
                              }}</small
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="level-right"></div>
                  </div>

                  <b-message
                      v-if="status.power_lost_at"
                      type="is-danger is-light"
                  >
                    Power has been lost at {{ status.power_lost_at }} ({{
                      status.power_lost_at | moment("from", "now")
                    }}).
                  </b-message>

                  <div
                      v-for="a_package in project.packages" :key="a_package.id"
                      class="level is-mobile mt-2 mb-4"
                  >
                    <div class="level-left">
                      <div class="level-item">
                        <status-indicator
                            style="display: inline-block; margin-right: 0.4rem"
                            :status="packagePowerStatus(a_package.status).indicator"
                            :pulse="packagePowerStatus(a_package.status).pulse"
                        />
                        <small class="hast-text">{{ a_package.name }}</small>
                      </div>
                    </div>
                    <div class="level-right">
                      <div class="level-item">
                        <b-tag :type="packagePowerStatus(a_package.status).type">{{
                            packagePowerStatus(a_package.status).msg
                          }}
                        </b-tag>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </card-component>
        </article>


      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import alarm from "../../store/modules/alarm.store";
import SchemeSyncStatusComponent from "@/components/package/schemes/SchemeSyncStatusComponent";
import ProjectArmDisarmStatusComponent from "@/components/package/schemes/ProjectArmDisarmStatusComponent";

export default {
  name: "ProjectAlarmDetailComponent",
  components: {CardComponent},
  props: ["status", "project"],

  data() {
    return {
      autorefresh: true,
      isLoading: false,
      partitions: [],
      power_partitions: [],
    };
  },

  created() {

  },

  mounted() {
    this.fetchData();
  },
  beforeDestroy: function () {
    clearInterval(this.intervalId);
  },
  methods: {
    fetchData() {

      this.partitions = [];

      let payload = {
        project_id: this.project.id,
        params: "include=partitions.zones",
      };
      // this.$store
      //   .dispatch("alarm/fetchAlarmDetails", payload)
      //   .then((response) => {
      //     let result = JSON.parse(JSON.stringify(response.data.data));
      //     this.partitions = result.partitions.filter(function (e) {
      //       return e.exist && !e.power_partition;
      //     });
      //     this.power_partitions = result.partitions.filter(function (e) {
      //       return e.exist && e.power_partition;
      //     });
      //
      //     this.isLoading = false;
      //   });
    },

    armOrDisarm(partition) {
      if (partition.armed === 1) {
        this.disarm(partition);
      } else if (partition.ready_to_arm === 1) {
        this.arm(partition);
      }
    },

    confirmArm() {
      this.$buefy.dialog.confirm({
        title: 'Arm Project',
        message: 'Are you sure you want to <b>arm</b> all packages in this project? ' +
            'Please make sure that the area is clear!',
        confirmText: 'Arm',
        type: 'is-danger',
        hasIcon: true,
        icon: "lock",
        onConfirm: () => this.arm()
      })
    },

    confirmDisarm() {
      this.$buefy.dialog.confirm({
        title: 'Disarm Project',
        message: 'Are you sure you want to <b>disarm</b> all packages in this project?',
        confirmText: 'Disarm',
        type: 'is-success',
        hasIcon: true,
        icon: "lock-open",
        onConfirm: () => this.disarm()
      })
    },

    arm() {
      this.isLoading = true;
      let payload = {
        project_id: this.project.id,
      };
      this.$store
          .dispatch("alarm/armProject", payload)
          .then((response) => {
            this.isLoading = false;
            this.actionResultModal('arm', response.data.package_updates)
          })
          .catch((error) => {
            this.$buefy.toast.open({
              message: error,
              type: "is-danger",
            });
            this.isLoading = false;

          });
    },
    disarm() {
      this.isLoading = true;
      let payload = {
        project_id: this.project.id,
      };
      this.$store
          .dispatch("alarm/disarmProject", payload)
          .then((response) => {
            this.isLoading = false;
            this.actionResultModal('disarm', response.data.package_updates)
          })
          .catch((error) => {
            this.$buefy.toast.open({
              message: error,
              type: "is-danger",
            });
            this.isLoading = false;

          });
    },

    actionResultModal(action, package_updates) {
      this.$buefy.modal.open({
        parent: this,
        component: ProjectArmDisarmStatusComponent,
        props: {
          package_updates: package_updates,
          action: action,
        },
        hasModalCard: true,
        trapFocus: true,
      })

    },
    projectStatus(status) {
      let result = {}
      if (status.alarm_status === 'not armed') {
        result = {
          type: "has-text-success",
          indicator: "positive",
          pulse: true,
          msg: "Ready to arm",
          icon: "lock-open-variant",
          color: "is-success",
        };
      } else if (status.alarm_status === 'armed') {
        result = {
          type: "has-text-danger",
          indicator: "negative",
          pulse: false,
          msg: "Armed",
          icon: "lock",
          color: "is-danger",
        };
      } else if (status.alarm_status === 'triggered') {
        result = {
          type: "has-text-danger",
          indicator: "negative",
          pulse: true,
          msg: "Triggered",
          icon: "lock-alert",
          color: "is-danger",
        };
      } else if (status.alarm_status === 'partially armed') {
        result = {
          type: "has-text-warning",
          indicator: "negative",
          pulse: false,
          msg: "Partially armed",
          icon: "lock-question",
          color: "is-warning",
        };
      } else {
        result = {
          type: "has-text-grey",
          indicator: "intermediary",
          pulse: false,
          msg: "Not ready",
          icon: "lock-question",
          color: "is-grey"
        };
      }

      if (!this.status.online) {
        // Long time ago
        result.type = "has-text-grey"
        result.color = "is-grey"
        result.indicator = ""
        result.problem = true
      }
      return result
    },
    packageStatus(status) {
      let result = {}
      if (status.alarm_status === 'not armed') {
        result = {
          indicator: "positive",
          type: "is-success is-light",
          msg: "not armed",
          pulse: false,
        };
      } else if (status.alarm_status === 'armed') {
        result = {
          indicator: "negative",
          type: "is-danger is-light",
          msg: "armed",
          pulse: false,
        };
      } else if (status.alarm_status === 'triggered') {
        result = {
          indicator: "negative",
          type: "is-danger is-light",
          msg: "triggered",
          pulse: true,
        };
      } else if (status.alarm_status === 'partially armed') {
        result = {
          indicator: "intermediary",
          type: "is-warning is-light",
          msg: "partially armed",
          pulse: false,
        };
      } else {
        result = {
          indicator: "",
          type: "is-light",
          msg: "unknown",
          pulse: false,
        };
      }
      if (!this.status.online) {
        // Long time ago
        result.type = "is-grey is-light";
        result.indicator = "";
        result.problem = true;
      }

      return result
    },

    packagePowerStatus(status) {
      let result = {}
      if (status.power_status === "no power") {
        result = {
          indicator: "negative",
          type: "is-danger is-light",
          msg: "not powered",
          color: "is-danger",
          pulse: false,
        };
      } else if (status.power_status === "powered") {
        result = {
          indicator: "positive",
          type: "is-success is-light",
          color: "is-success",
          msg: "powered",
          pulse: false,
        };
      } else {
        result = {
          indicator: "",
          type: "is-grey is-light",
          color: "is-grey-light",
          msg: "unknown",
          pulse: false,
        };
      }
      if (!this.status.online) {
        // Long time ago
        result.type = "is-grey-light is-light";
        result.color =  "is-grey";
        result.indicator = "";
        result.problem = true;
      }
      return result
    }
  }

}
</script>

<style scoped>
</style>
