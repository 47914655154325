<template>

  <section class="wrapper hero is-fullheight is-error-section">

    <div id="over_map">
      <div class="box level">
        <div class="level-left">
          <a @click="$router.go(-1)" class="level-item">
            <b-icon type="is-grey-darker" icon="arrow-left"></b-icon>
          </a>
          <div class="level-item">
            <b>TowerEye </b> Portal
          </div>
        </div>


      </div>
    </div>

    <mapbox style="height: 100vh" ref="bigmap" :access-token="accessToken" :map-options="mapStyle"
            :geolocate-control="{
                              show: true,
                              position: 'top-right',
                            }"
            @map-init="mapInitialized">
    </mapbox>
    <div class="hero-foot has-text-centered">
      <div class="logo"></div>
    </div>
  </section>
</template>

<script>

import settings from "@/store/modules/settings.store"
import Mapbox from 'mapbox-gl-vue'
import mapbox from "@/store/modules/mapbox.store";
import packages from "@/store/modules/packages.store";
import CardComponent from "@/components/CardComponent";
const GeoJSON = require('geojson');

export default {
  name: 'FullscreenMap',
  components: {CardComponent, Mapbox },
  data () {
    return {
      accessToken: 'pk.eyJ1IjoibHVja2FzZGMiLCJhIjoiY2sxdWR6d3B5MDkwOTNpcGFuNXh3dGhzNSJ9.nBYgv6LPBGFGDx56p8mRQQ',
      mapStyle: {
        style: 'mapbox://styles/luckasdc/ckf29lnav0mm319qvg7u55h7l',
        container: "projectformmap"
      },

      isLoading: false,

    }
  },
  created() {

    if(!settings.isRegistered) {
      this.$store.registerModule('settings', settings)
      settings.isRegistered = true
    }
    if(!mapbox.isRegistered) {
      this.$store.registerModule('mapbox', mapbox)
      mapbox.isRegistered = true
    }
    if(!packages.isRegistered) {
      this.$store.registerModule('packages', packages)
      packages.isRegistered = true
    }

    // this.$store.commit('settings/asideRightToggle', false)
    // this.$store.commit('settings/asideMobileStateToggle', false)
    // this.$store.commit('settings/isNavbarVisible', false)


    this.$store.commit('settings/fullPage', true)
    this.$store.commit('settings/asideRightToggle', false)

  },
  beforeMount() {
    this.fetchPackages()
  },

  mounted() {
    this.fetchPackages();
    this.intervalId = window.setInterval(() => {
      this.fetchPackagesSilent();
    }, 5000);
  },

  beforeDestroy () {
    this.$store.commit('settings/fullPage', false)
    clearInterval(this.intervalId);

  },

  computed: {

    mymap() {
      return this.$store.state.mapbox.mymap
    },
    mapbox() {
      return this.$store.state.mapbox.mapbox
    },
  },

  methods: {

    mapInitialized(map) {
      this.$store.dispatch('mapbox/initMap', map)
    },

    fetchPackages() {

        this.loading  = true;
        this.$store.dispatch('mapbox/setHeader', {
          stack: null,
          status: null
        })

        this.$store.dispatch('packages/fetchPackages', [])
            .then(response => {
              if(response.data.data.length > 0) {
                const geo = GeoJSON.parse(response.data.data, {Point: ['lat', 'long']});
                this.$store.dispatch('mapbox/drawPackages', { ctx: this, geo: geo, packages: response.data.data, snap: true} )
              }

              this.loading = false
            })
    },

    fetchPackagesSilent() {
      this.$store.dispatch('packages/fetchPackages', [])
          .then(response => {
            if(response.data.data.length > 0) {
              this.$store.dispatch('mapbox/drawPackagesWithoutSnap', { ctx: this, packages: response.data.data} )
            }
          })
    }
  }
}
</script>

<style lang="scss" scoped>
#wrapper {
  position: relative;
}
#over_map {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 30%;
  z-index: 3; }


</style>